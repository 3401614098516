import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.ADD_RESIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.GET_RESIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.UPDATE_DELETE_RESIDENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* activateResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.ACTIVATE_RESIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listIncidents(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.INCIDENT.LIST_INCIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* listMedication(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.LIST_MEDICATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* residentListwithoutCompanys(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST_WITHOUT_COMPANY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* checkNewResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.THIRD_PARTY_COMPANY_SUBMIT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* listMedicationsAdmin(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.LISTS_MEDI, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* newListMedicationsAdmin(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.ADMIN_LISTS_MEDI, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}