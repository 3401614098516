import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useCommonApi() {
   const callApi = useApiCall();

   const uploadFiles = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.UPLOAD_FILES, data, onSuccess, onError);
   }

   const uploadFile = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.UPLOAD_FILE, data, onSuccess, onError);
   }

   const deleteFiles = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.DELETE_FILES, data, onSuccess, onError);
   }

   return {
      uploadFile,
      uploadFiles,
      deleteFiles
   }
}