import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput } from "../form-elements/textInput";
import { MaskInput } from "../form-elements/maskInput";
import { SelectInput } from "../form-elements/dropdown";
import { useCompanyApi } from "../../hooks/api/companyApiHook";
import { useStateCityApi } from "../../hooks/api/stateCityApiHook";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { RadioGroup } from "../form-elements/radioGroup";
import debounce from "lodash.debounce";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { DateInput } from "../form-elements/datepicker";
import { useCommonApi } from "../../hooks/api/commonApiHook";
import ImageModal from "../form-elements/imageModal";

const blank_user = {
  id: null,
  userType: 2,
  isActive: "1",
  firstName: "",
  lastName: "",
  username: "",
  birthday: "",
  phone: "",
  email: "",
  ssn: "",
  photo: {},
  isExists: "false",
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Company name is required")
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      "Company can contain only letters, numbers and spaces"
    )
    .max(20, "Max 20 characters"),
  addrLine1: yup
    .string()
    .required("Address line 1 is required")
    .max(40, "Max 40 characters"),
  addrLine2: yup.string().max(40, "Max 40 characters"),
  zipCode: yup
    .string()
    .required("Zip is required")
    .matches(
      /^[a-zA-Z0-9\\-]+$/i,
      'Zip can only contain letters, numbers and "-"'
    )
    .min(5, "Minimum 5 characters"),
  phone: yup
    .string()
    .required("Phone is required")
    .transform((val) => val.replace(/[^\d]/g, ""))
    .test("len", "Invalid phone number", (val) => val.length === 10),
  state: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("State is required"),
  users: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .required("First name is required")
        .matches(/^[a-zA-Z]+$/, "Invalid first name")
        .max(20, "Max 20 characters"),
      lastName: yup
        .string()
        .required("Last name is required")
        .matches(/^[a-zA-Z ]+$/, "Invalid last name")
        .max(40, "Max 40 characters"),
      username: yup.string().required("Username is required"),
      phone: yup
        .string()
        .required("Phone is required")
        .transform((val) => val.replace(/[^\d]/g, ""))
        .test("len", "Invalid phone number", (val) => val.length === 10),
      ssn: yup
        .string()
        // .required("SSN is required")
        .nullable()
        .max(20, "Max 20 characters")
        .transform((val) => val.replace(/[^\d]/g, ""))
        .test("len", "Invalid SSN", (val) => {
          if (!val) return true;
          return val.length === 9;
        }),
      email: yup.string().email("Invalid email").required("Email is required"),
      birthday: yup
        .string()
        .nullable()
        .test("len", "Invalid date of birth", function (value) {
          if (!value) return true;
          value = value && value.replace(/_/g, "");
          return (
            value &&
            value.length === 10 &&
            moment(value, "MM-DD-YYYY").isValid() &&
            moment(value, "MM-DD-YYYY").isSameOrBefore()
          );
        }),
    })
  ),
});

const CompanyForm = (props) => {
  const [users, setUsers] = useState([]);
  const [alert, setAlert] = useState(null);
  const [stateList, setStates] = useState([]);
  const [cityList, setCities] = useState([]);
  const [selectedCity, setCity] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [menuIsOpen, setMenuOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [photo, setPhoto] = useState({});
  const [name, setName] = useState("");

  const [emailSearch, setEmailSearch] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailMenuIsOpen, setEmailMenuOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [formInitialised, setFormInitialised] = useState(false);

  const [disableIndex, setDisableIndex] = useState("");
  const [companyDetails, setCompanyDetails] = useState({});

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
    getValues,
    watch,
    register,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "users",
  });

  const companyApi = useCompanyApi();
  const history = useHistory();
  const stateCityApi = useStateCityApi();
  const commonApi = useCommonApi();
  const selectedState = watch("state");
  const formValues = watch();

  useEffect(() => {
    if (props.companyId) {
      getCompany();
    } else {
      setValue("users", [blank_user]);
    }
    getStatesList();
    setFormInitialised(true);
  }, [!formInitialised]);

  const getStatesList = useCallback(() => {
    stateCityApi.listStates(
      { search: "" },
      (message, resp) => {
        setStates(
          resp.length
            ? resp.map((item) => {
                return { label: item.state_code, value: item.id };
              })
            : []
        );
      },
      (message) => {}
    );
  }, []);

  const unAssignUser = (id, index) => {
    setAlert(null);
    companyApi.unAssignUser(
      { companyId: props.companyId, userId: id },
      (message, resp) => {
        remove(index);
        setTimeout(() => {
          insert(index, blank_user);
        }, 100);
        let temp = companyDetails.users;
        temp.splice(index, 1);
        setSelectedEmail({ label: "", value: "" });
        setCompanyDetails({
          ...companyDetails,
          users: temp,
        });
        toast.success("User unassigned successfully");
      },
      (message) => {
        toast.error(message);
      }
    );
  };

  const getCompany = () => {
    companyApi.companyDetails(
      { id: props.companyId },
      (message, resp) => {
        resp.isActive = resp.isActive == true ? "1" : false;
        resp.state = resp.state
          ? { label: resp.state.state_code, value: resp.state.id }
          : "";
        setCity(
          resp.city && resp.state ? { label: resp.city, value: "adbc54" } : ""
        );
        setName(": " + resp.name);
        if (resp.companyUser) {
          let user = {
            label: `${resp.companyUser.firstName} ${resp.companyUser.lastName} (${resp.companyUser.email})`,
            value: resp.companyUser.id,
          };
          // resp.companyUser.email = { label: "sdsd", value: "ss" };
          setSelectedEmail({
            label: resp.companyUser.email,
            value: resp.companyUser.email,
          });
          resp.users = [
            {
              ...resp.companyUser,
              isActive: resp.companyUser.isActive === true ? "1" : "0",
              birthday: resp?.companyUser?.birthDate ? moment(resp.companyUser.birthDate, "YYYY-MM-DD").format(
                "MM-DD-YYYY"
              ) : null,
              isExists: "true",
            },
          ];
        } else {
          resp.users = [blank_user];
        }
        reset(resp);
        setCompanyDetails(resp);
      },
      (message, resp) => {
        if (resp.status === 404) {
          toast.error("No such company found");
          setTimeout(() => {
            history.replace("/company-list");
          }, 500);
        } else {
          toast.error(message);
        }
      }
    );
  };

  const uploadImg = () => {
    let formData = new FormData();
    formData.append("file", photo.file);
    formData.append("fileType", "profile");

    commonApi.uploadFile(
      { data: formData, format: "image" },
      (message, resp) => {
        setValue(`users[${photo.index}].photo`, resp);
        setOpen(false);
        toast.success("Please save to confirm changes");
      },
      (message) => {
        toast.error(message);
      }
    );
  };

  const handleImg = (e, index) => {
    let file = e.target.files[0];
    if (file) {
      let url = URL.createObjectURL(file);
      setPhoto({ fullUrl: url, file, index });
      setOpen(true);
    }
  };

  const closeModal = () => {
    document.getElementById(`users[${photo.index}].fileInput`).value = "";
    setOpen(false);
    setPhoto({});
  };

  const onSubmit = (data) => {
    data["users"] = data.users.map((user) => {
      if (user.photo && user.photo.id) {
        user["photoId"] = user.photo.id;
      }
      user["isActive"] = user.isActive === "1" ? true : false;
      user["birthday"] = user.birthday ? moment(user.birthday, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      ) : null;
      if (user["isExists"] === "false") {
        delete user["id"];
      }
      return user;
    });

    if (!selectedCity || !selectedCity.label) {
      toast.error("Please choose a city");
      return;
    }
    if (selectedCity && selectedCity.label.length > 100) {
      toast.error("Max length for city is 100 characters");
      return;
    }

    data.stateId = parseInt(data.state.value);
    data.city = selectedCity.label.trim();
    if(data?.thirdPartyApiFacility){
         data['thirdPartyApiFacility']=data?.thirdPartyApiFacility
      }
      else{
         data['thirdPartyApiFacility']="0" 
      }

    if (props.companyId) {
      data["id"] = props.companyId;
      companyApi.updateCompany(
        data,
        (message, resp) => {
          toast.success("Company updated successfully");
          history.push("/company-list");
        },
        (message) => {
          toast.error(message);
        }
      );
    } else {
      companyApi.createCompany(
        data,
        (message, resp) => {
          toast.success("Company created successfully");
          history.push("/company-list");
        },
        (message) => {
          toast.error(message);
        }
      );
    }
  };

  const showConfirm = (id, index) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Continue!"
        confirmBtnBsStyle="danger"
        title="Unassign User?"
        onConfirm={() => unAssignUser(id, index)}
        onCancel={() => setAlert(null)}
        focusCancelBtn={false}
      >
        Are you sure?
      </SweetAlert>
    );
  };

  const resetCity = () => {
    if (selectedState && selectedState.value) {
      setCities([]);
      setCity("");
    }
  };

  const handleCitySearch = (inputValue = "") => {
    setLoading(true);
    stateCityApi.listCities(
      {
        page: 1,
        limit: 10,
        search: inputValue,
        state: getValues().state.value,
      },
      (message, resp) => {
        let temp = resp.data.map((item) => {
          return { label: `${item.city}`, value: item.id };
        });
        setCities(temp);
        setCitySearch(inputValue);
        setLoading(false);
        setMenuOpen(true);
      },
      (message) => {
        //toast.error(message)
      }
    );
  };

  const handleEmailSearch = (inputValue = "") => {
    setEmailLoading(true);
    companyApi.unAssignedUsers(
      {
        page: 1,
        limit: 10,
        search: inputValue,
      },
      (message, resp) => {
        let temp = resp.data.map((item) => {
          return {
            ...item,
            value: item.email,
            label: item.email,
          };
        });
        setUsers(temp);
        setEmailSearch(inputValue);
        setEmailLoading(false);
        setEmailMenuOpen(true);
        setDisableIndex(0);
      },
      (message) => {
        //toast.error(message)
      }
    );
  };

  const searchFunc = useCallback(
    debounce((val, type) => {
      if (type === "city") {
        handleCitySearch(val);
      } else {
        handleEmailSearch(val);
      }
    }, 1500),
    []
  );

  const typeAndSearch = (val, type) => {
    setDisableIndex(0);
    if (val.length >= 3) {
      searchFunc(val, type);
    }
    if (type === "city") {
      setCitySearch(val);
    } else {
      setEmailSearch(val);
    }
  };

  const handleEmailSelect = (val, index, isExist) => {
    if (isExist === "true") {
      let temp = users.find((item) => item.email === val.value);
      remove(index);
      setTimeout(() => {
        insert(index, {
          ...temp,
          isActive: temp.isActive === true ? "1" : "0",
          birthday: moment(temp.birthDate, "YYYY-MM-DD").format("MM-DD-YYYY"),
          isExists: "true",
        });
        setSelectedEmail(val);
      }, 100);
    } else {
      remove(index);
      setTimeout(() => {
        insert(index, {
          ...blank_user,
          email: val,
          username: val,
        });
        setSelectedEmail({ label: val, value: val });
        setDisableIndex("");
      }, 100);
    }
  };

  return (
    <>
      {alert}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="header-button">
          <h5>
            {props.heading}
            {name}
          </h5>
          <button className="btn btn-info" type="submit">
            Save Details
          </button>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-stretch">
            <div className="card w-100">
              <div className="card-header w-100 h-60 d-flex justify-content-between align-items-center">
                <h4 className="title my-0">Company Details</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <Controller
                        defaultValue=""
                        control={control}
                        name="name"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            value={value}
                            error={errors.name}
                            label="Company Name"
                            required
                            type="text"
                            maxLength={20}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <Controller
                        defaultValue=""
                        control={control}
                        name="addrLine1"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            value={value}
                            error={errors.addrLine1}
                            label="Address Line 1"
                            required
                            type="text"
                            maxLength={40}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <Controller
                        defaultValue=""
                        control={control}
                        name="addrLine2"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            value={value}
                            error={errors.addrLine2}
                            label="Address Line 2"
                            type="text"
                            maxLength={40}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6" data-select2-id={1}>
                    <div className="form-group" data-select2-id={15}>
                      <label className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      <Controller
                        defaultValue=""
                        control={control}
                        name="state"
                        render={({ ref, value, onChange, ...rest }) => (
                          <SelectInput
                            error={errors.state}
                            inputref={ref}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              resetCity();
                            }}
                            options={stateList}
                            {...rest}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" data-select2-id={1}>
                    <div className="form-group" data-select2-id={15}>
                      <label className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <CreatableSelect
                        name="city"
                        onBlur={() => setMenuOpen(false)}
                        menuIsOpen={menuIsOpen}
                        inputValue={citySearch}
                        onInputChange={(val) => typeAndSearch(val, "city")}
                        onChange={(val) => setCity(val)}
                        options={cityList}
                        createOptionPosition="first"
                        cacheOptions
                        value={selectedCity}
                        blurInputOnSelect
                        isLoading={loading}
                        allowCreateWhileLoading={false}
                        placeholder={"City"}
                        isDisabled={!selectedState || !selectedState.value}
                      />
                      <small className="text-muted font-weight-bold float-right mt-1">
                        (Input at least 3 characters)
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <Controller
                        defaultValue=""
                        control={control}
                        name="zipCode"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            value={value}
                            error={errors.zipCode}
                            placeholder="Zip"
                            label="Zip"
                            required
                            type="text"
                            maxLength={10}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <Controller
                        defaultValue=""
                        control={control}
                        name="phone"
                        render={({ ref, value, ...props }) => (
                          <MaskInput
                            mask="(999) 999-9999"
                            maskPlaceholder="Phone"
                            inputref={ref}
                            value={value}
                            error={errors.phone}
                            label="Phone"
                            required
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                    <label className="form-label">
                    Using SWS Pharmacy?
                      </label>
                      <div>
                    <Controller
                        defaultValue={''}
                        control={control}
                        name="thirdPartyApiFacility"
                        render={({ ref, value, ...props }) => (
                           <RadioGroup
                              name="thirdPartyApiFacility"
                              value={value}
                              inputref={ref}
                              options={[
                                 { label: 'Yes', value: 1 },
                                 { label: 'No', value: 0 }
                              ]}
                              {...props}
                           />
                        )}
                     />
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-stretch">
            {fields.map((item, index) => (
              <div className="card w-100" key={index}>
                <div className="card-header w-100 h-60 d-flex justify-content-between align-items-center">
                  <h4 className="title my-0">Owner Details</h4>
                  {companyDetails?.users?.[index]?.id && (
                    <a
                      href="#"
                      className="btn btn-danger"
                      title="Unassign user"
                      onClick={(e) => {
                        e.preventDefault();
                        showConfirm(companyDetails?.users?.[index]?.id);
                      }}
                    >
                      <i className="fa fa-user-times"></i>
                    </a>
                  )}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group" data-select2-id={15}>
                        <label className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <CreatableSelect
                          onBlur={() => setEmailMenuOpen(false)}
                          menuIsOpen={emailMenuIsOpen}
                          inputValue={emailSearch}
                          onInputChange={(val) => typeAndSearch(val, "email")}
                          onChange={(val) =>
                            handleEmailSelect(val, index, "true")
                          }
                          options={users}
                          onCreateOption={(val) =>
                            handleEmailSelect(val, index, "false")
                          }
                          createOptionPosition="first"
                          cacheOptions
                          value={selectedEmail}
                          blurInputOnSelect
                          isLoading={emailLoading}
                          allowCreateWhileLoading={false}
                          placeholder={"Email"}
                          error={errors?.users?.[index]?.email}
                          isDisabled={
                            companyDetails?.users?.[index]?.id ||
                            formValues?.users?.[index].isExists === "true"
                          }
                        />
                        <small className="text-muted font-weight-bold float-right mt-1">
                          (Input at least 3 characters)
                        </small>
                        {errors?.users?.[index]?.email && (
                          <div className="text-danger">
                            <small>
                              {errors?.users?.[index]?.email?.message}
                            </small>
                          </div>
                        )}
                      </div>
                      <input
                        defaultValue={item.email}
                        type="hidden"
                        id="user-email"
                        name={`users[${index}].email`}
                        ref={register()}
                      />
                      <input
                        defaultValue={item.isExists}
                        type="hidden"
                        name={`users[${index}].isExists`}
                        ref={register()}
                      />
                      <input
                        defaultValue={item.id}
                        type="hidden"
                        id="user-id"
                        name={`users[${index}].id`}
                        ref={register()}
                      />
                    </div>
                  </div>
                  <fieldset
                  // disabled={
                  //   companyDetails?.users?.[index]?.id ||
                  //   formValues?.users?.[index].isExists === "true" ||
                  //   disableIndex === index
                  // }
                  >
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <Controller
                            defaultValue={item.firstName}
                            control={control}
                            name={`users[${index}].firstName`}
                            render={({ ref, value, ...props }) => (
                              <TextInput
                                inputref={ref}
                                value={value}
                                error={errors?.users?.[index]?.firstName}
                                label="First Name"
                                required
                                placeholder="First name"
                                type="text"
                                maxLength={20}
                                {...props}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <Controller
                            defaultValue={item.lastName}
                            control={control}
                            name={`users[${index}].lastName`}
                            render={({ ref, value, ...props }) => (
                              <TextInput
                                inputref={ref}
                                type="text"
                                value={value}
                                error={errors?.users?.[index]?.lastName}
                                label="Last Name"
                                required
                                placeholder="Last name"
                                maxLength={40}
                                {...props}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group" data-select2-id={15}>
                          <Controller
                            defaultValue={item.ssn}
                            control={control}
                            name={`users[${index}].ssn`}
                            render={({ ref, value, ...props }) => (
                              <MaskInput
                                mask="999-99-9999"
                                maskPlaceholder="SSN"
                                inputref={ref}
                                value={value}
                                error={errors?.users?.[index]?.ssn}
                                label="SSN"
                                // required
                                {...props}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Date of Birth 
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Controller
                            defaultValue={item.birthday}
                            control={control}
                            name={`users[${index}].birthday`}
                            render={({ ref, value, ...props }) => (
                              <DateInput
                                inputref={ref}
                                value={value}
                                error={errors?.users?.[index]?.birthday}
                                placeholder="mm-dd-yyyy"
                                {...props}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <Controller
                            defaultValue={item.phone}
                            control={control}
                            name={`users[${index}].phone`}
                            render={({ ref, value, ...props }) => (
                              <MaskInput
                                mask="(999) 999-9999"
                                maskPlaceholder="Phone"
                                inputref={ref}
                                value={value}
                                error={errors?.users?.[index]?.phone}
                                label="Phone"
                                required
                                {...props}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-6" data-select2-id={8}>
                        <div className="form-group">
                          <Controller
                            defaultValue={item.username}
                            control={control}
                            name={`users[${index}].username`}
                            render={({ ref, value, ...rest }) => (
                              <TextInput
                                inputref={ref}
                                value={value}
                                label="Username"
                                required
                                error={errors?.users?.[index]?.username}
                                maxLength={20}
                                placeholder="Username"
                                type="text"
                                {...rest}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Controller
                            defaultValue={item.isActive}
                            control={control}
                            name={`users[${index}].isActive`}
                            render={({ ref, value, ...rest }) => (
                              <RadioGroup
                                name={`users[${index}].isActive`}
                                value={value}
                                inputref={ref}
                                options={[
                                  { label: "Active", value: "1" },
                                  { label: "Inactive", value: "0" },
                                ]}
                                {...rest}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group photo-display">
                          <div className="d-none">
                            <Controller
                              defaultValue={item.photo}
                              control={control}
                              name={`users[${index}].photo`}
                              render={({ ref, value, onChange, ...rest }) => (
                                <SelectInput
                                  inputref={ref}
                                  value={value}
                                  onChange={onChange}
                                  options={[]}
                                  {...rest}
                                />
                              )}
                            />
                          </div>
                          <label className="form-label">Photo</label>
                          {formValues?.["users"]?.[index]?.photo &&
                          formValues?.["users"]?.[index]?.photo.id ? (
                            <div>
                              <img
                                src={formValues["users"][index].photo.fullUrl}
                                alt="avatar"
                                className="d-block mb-3"
                                style={{ maxHeight: "150px", maxWidth: "100%" }}
                              />
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  setValue(`users[${index}].photo`, {})
                                }
                                type="button"
                              >
                                Remove Photo
                              </button>
                            </div>
                          ) : (
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={(e) => handleImg(e, index)}
                                accept=".jpg,.jpeg,.png"
                                id={`users[${index}].fileInput`}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="fileInput"
                              >
                                Choose file
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
      <ImageModal
        isOpen={isOpen}
        url={photo && photo.fullUrl ? photo.fullUrl : ""}
        close={closeModal}
        onSave={uploadImg}
      />
    </>
  );
};

export default CompanyForm;
