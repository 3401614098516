import React, { Component, useEffect } from 'react'

const UnAuthLayout = (props) => {

   useEffect(() => {
      document.body.classList.add('flex-row','align-items-center')

      return () => {
         document.body.classList.remove('flex-row','align-items-center')
      }
   }, [])

   return(
      <>
      {props.children}
      </>
   )
} 

export default UnAuthLayout