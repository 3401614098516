import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* createCompany(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.COMPANY.CREATE_COMPANY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* companyList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMPANY.LIST_COMPANY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* companyListForAdmin(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMPANY.LIST_COMPANY_FOR_ADMIN, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* createCompanyUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.CREATE_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* companyUserList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.LIST_USERS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* unAssignedUsers(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMPANY.COMPANY_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* companyDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMPANY.COMPANY_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateCompanyDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.COMPANY.UPDATE_DELETE_COMPANY + action.payload.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteCompany(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.COMPANY.UPDATE_DELETE_COMPANY + action.payload.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* unAssignUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.COMPANY.UNASSIGN_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* companyActivate(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMPANY.COMPANY_ACTIVATE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}