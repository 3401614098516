import { API_BASE_URL, SITE_BASE_URL } from "./site_urls";

export const STORAGE = 'eh-ui-auth-key';

export const SITE_URL = SITE_BASE_URL

export const BASE_URL = API_BASE_URL

export const APIS = {
   ADMIN: {
      DASHBOARD: `${BASE_URL}/admin/dashboard`
   },
   USER: {
      LOGIN: `${BASE_URL}/auth/admin-login`,
      ME: `${BASE_URL}/auth`,
      LOGOUT: `${BASE_URL}/auth/logout`,
      REGISTER: `${BASE_URL}/auth/register`,
      FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
      RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
      UPDATE_PROFILE: `${BASE_URL}/auth/update-profile`,
      CHECK_TOKEN: `${BASE_URL}/auth/verify-email`,
      LIST_USERS: `${BASE_URL}/users/user-list`,
      CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
      CREATE_USER: `${BASE_URL}/users/create-user`,
      UPDATE_DELETE_USER: `${BASE_URL}/users/`,
      GET_USER: `${BASE_URL}/users/user-details`,
      CHANGE_USER_STATE: `${BASE_URL}/users/user-activate`,
   },
   COMPANY: {
      COMPANY_USER: `${BASE_URL}/users/company-user`,
      CREATE_COMPANY: `${BASE_URL}/company/create-company-v2`,
      LIST_COMPANY: `${BASE_URL}/company/company-list`,
      COMPANY_DETAILS: `${BASE_URL}/company/company-details`,
      UPDATE_DELETE_COMPANY: `${BASE_URL}/company/`,
      UNASSIGN_USER: `${BASE_URL}/company/unassign-company-user`,
      COMPANY_ACTIVATE: `${BASE_URL}/company/company-activate`,
      LIST_COMPANY_FOR_ADMIN: `${BASE_URL}/company/company-list-for-admin`
   },
   RELATIONS: {
      CREATE_RELATION: `${BASE_URL}/relation/create-relation`,
      UPDATE_DELETE_RELATION: `${BASE_URL}/relation/`,
      LIST_RELATION: `${BASE_URL}/relation/relation-list`,
      GET_RELATION: `${BASE_URL}/relation/relation-details`
   },
   TIMINGS: {
      CREATE_TIMING: `${BASE_URL}/med-timing/create-timing`,
      UPDATE_DELETE_TIMING: `${BASE_URL}/med-timing/`,
      LIST_TIMING: `${BASE_URL}/med-timing/med-timing-list`
   },
   ROLES: {
      LIST_ROLES: `${BASE_URL}/permission/roles-list`,
   },
   STATE_CITY: {
      CITY_LIST: `${BASE_URL}/state-city/city-list`,
      ADD_CITY: `${BASE_URL}/state-city/create-city`,
      UPDATE_DELETE_CITY: `${BASE_URL}/state-city/city/`,
      GET_CITY: `${BASE_URL}/state-city/city-details`,
      STATE_LIST: `${BASE_URL}/state-city/state-list`,
      ADD_STATE: `${BASE_URL}/state-city/create-state`,
      UPDATE_DELETE_STATE: `${BASE_URL}/state-city/state/`,
      GET_STATE: `${BASE_URL}/state-city/state-details`,
   },
   FACILITY: {
      FACILITY_LIST: `${BASE_URL}/facility/facility-list`,
      ADD_FACILITY: `${BASE_URL}/facility/create-facility`,
      UPDATE_DELETE_FACILITY: `${BASE_URL}/facility/`,
      ADMIN_FACILITY_LIST: `${BASE_URL}/facility/admin-facility-list`,
      GET_FACILITY: `${BASE_URL}/facility/facility-details`,
   },
   RESIDENT: {
      RESIDENT_LIST: `${BASE_URL}/residents/resident-list`,
      // UPDATE_DELETE_RESIDENT: `${BASE_URL}/residents/`,
      UPDATE_DELETE_RESIDENT: `${BASE_URL}/residents/admin-update/`,
      ADD_RESIDENT: `${BASE_URL}/residents/create-resident`,
      GET_RESIDENT: `${BASE_URL}/residents/resident-details`,
      ACTIVATE_RESIDENT: `${BASE_URL}/residents/activate-resident`,
      INCIDENT: {
         CREATE_INCIDENT: `${BASE_URL}/residents/create-resident-incident`,
         UPDATE_INCIDENT: `${BASE_URL}/residents/update-resident-incident/`,
         LIST_INCIDENT: `${BASE_URL}/residents/incident-list`,
         GET_INCIDENT: `${BASE_URL}/residents/resident-incident-details`,
      },
      RESIDENT_LIST_WITHOUT_COMPANY: `${BASE_URL}/residents/resident-list-with-no-company`,
      THIRD_PARTY_COMPANY_SUBMIT: `${BASE_URL}/residents/third-party-api-for-company`,
      
   },
   COMMON: {
      UPLOAD_FILE: `${BASE_URL}/common/upload-file`,
      UPLOAD_FILES: `${BASE_URL}/common/upload-files`,
      DONWLOAD_FILE: `${BASE_URL}/common/download-file`,
      DELETE_FILES: `${BASE_URL}/common/delete-files`,
   },
   ANCMENT: {
      UPDATE_DELETE_ANCMENT: `${BASE_URL}/announcements/`,
      GET_ANCMENT: `${BASE_URL}/announcements/announcements-details`,
      CREATE_ANCMENT: `${BASE_URL}/announcements/create-announcements`,
      LIST_ANCMENTS: `${BASE_URL}/announcements/announcements-list`,
   },
   MEDICATION: {
      LIST_MEDICATION: `${BASE_URL}/medication/medication-list`,
      LISTS_MEDI: `${BASE_URL}/medication/medication-list-for-admin`,
      ADMIN_LISTS_MEDI: `${BASE_URL}/medication/admin-temporary-medication-list`
      
   },
   INCIDENT_TYPES: {
      ADD_INCIDENT_TYPE: `${BASE_URL}/incident/create-incident-type`,
      UPDATE_INCIDENT_TYPE: `${BASE_URL}/incident/update-incident-type/`,
      LIST_INCIDENT_TYPES: `${BASE_URL}/incident/list-incident-types`,
      DELETE_INCIDENT_TYPE: `${BASE_URL}/incident/delete-incident-type/`,
   },
   CONTACT_TYPE: {
      CREATE_CONTACT_TYPE: `${BASE_URL}/contact-types/create-contact-type`,
      UPDATE_DELETE_CONTACT_TYPE: `${BASE_URL}/contact-types/`,
      LIST_CONTACT_TYPE: `${BASE_URL}/contact-types/list-contact-types`,
      GET_CONTACT_TYPE: `${BASE_URL}/contact-types/get-contact-type`,
   },
}

export const ACTIONS = {
   USER: {
      LOGIN: 'ACTION/USER/LOGIN',
      ME: 'ACTION/USER/ME',
      LOGOUT: 'ACTION/USER/LOGOUT',
      REGISTER: {
         REGISTER_USER: 'ACTION/USER/REGISTER/REGISTER_USER'
      }
   },
   LOADER: {
      SHOW_LOADER: 'ACTION/LOADER/SHOW_LOADER',
      HIDE_LOADER: 'ACTION/LOADER/HIDE_LOADER'
   },
   EXTRAS: {
      SIDEBAR: 'ACTION/LOADER/SIDEBAR'
   }
}

export const SAGA_ACTIONS = {
   ADMIN: {
      DASHBOARD: 'SAGA_ACTION/ADMIN/DASHBOARD'
   },
   USER: {
      LOGIN: 'SAGA_ACTION/USER/LOGIN',
      ME: 'SAGA_ACTION/USER/ME',
      LOGOUT: 'SAGA_ACTION/USER/LOGOUT',
      REGISTER: 'SAGA_ACTION/USER/REGISTER/REGISTER_USER',
      FORGOT_PASSWORD: 'SAGA_ACTION/USER/FORGOT_PASSWORD',
      CHANGE_PASSWORD: 'SAGA_ACTION/USER/CHANGE_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTION/USER/RESET_PASSWORD',
      UPDATE_PROFILE: 'SAGA_ACTION/USER/UPDATE_PROFILE',
      CHECK_TOKEN: 'SAGA_ACTION/USER/CHECK_TOKEN',
      CREATE_USER: 'SAGA_ACTION/USER/CREATE_USER',
      LIST_USERS: 'SAGA_ACTION/USER/LIST_USERS',
      UPDATE_USER: 'SAGA_ACTION/USER/UPDATE_USER',
      GET_USER: 'SAGA_ACTION/USER/GET_USER',
      CHANGE_USER_STATE: 'SAGA_ACTION/USER/CHANGE_USER_STATE',
      DELETE_USER: 'SAGA_ACTION/USER/DELETE_USER'
   },
   COMPANY: {
      COMPANY_USER: 'SAGA_ACTION/COMPANY/COMPANY_USER',
      CREATE_COMPANY: 'SAGA_ACTION/COMPANY/CREATE_COMPANY',
      LIST_COMPANY: 'SAGA_ACTION/COMPANY/LIST_COMPANY',
      COMPANY_DETAILS: 'SAGA_ACTION/COMPANY/COMPANY_DETAILS',
      UPDATE_COMPANY: 'SAGA_ACTION/COMPANY/UPDATE_COMPANY',
      DELETE_COMPANY: 'SAGA_ACTION/COMPANY/DELETE_COMPANY',
      UNASSIGN_USER: 'SAGA_ACTION/COMPANY/UNASSIGN_USER',
      COMPANY_ACTIVATE: 'SAGA_ACTION/COMPANY/COMPANY_ACTIVATE',
      LIST_COMPANY_FOR_ADMIN: 'SAGA_ACTION/COMPANY/LIST_COMPANY_FOR_ADMIN',
   },
   RELATIONS: {
      CREATE_RELATION: 'SAGA_ACTION/RELATIONS/CREATE_RELATION',
      UPDATE_RELATION: 'SAGA_ACTION/RELATIONS/UPDATE_RELATION',
      LIST_RELATION: 'SAGA_ACTION/RELATIONS/LIST_RELATION',
      DELETE_RELATION: 'SAGA_ACTION/RELATIONS/DELETE_RELATION',
      GET_RELATION: 'SAGA_ACTION/RELATIONS/GET_RELATION'
   },
   TIMINGS: {
      CREATE_TIMING: 'SAGA_ACTION/TIMINGS/CREATE_TIMING',
      UPDATE_TIMING: 'SAGA_ACTION/TIMINGS/UPDATE_TIMING',
      LIST_TIMING: 'SAGA_ACTION/TIMINGS/LIST_TIMING',
      DELETE_TIMING: 'SAGA_ACTION/TIMINGS/DELETE_TIMING'
   },
   ROLES: {
      LIST_ROLES: 'SAGA_ACTION/ROLES/LIST_ROLES'
   },
   STATE_CITY: {
      CITY_LIST: 'SAGA_ACTION/STATE_CITY/CITY_LIST',
      ADD_CITY: 'SAGA_ACTION/STATE_CITY/ADD_CITY',
      UPDATE_CITY: 'SAGA_ACTION/STATE_CITY/UPDATE_CITY',
      DELETE_CITY: 'SAGA_ACTION/STATE_CITY/DELETE_CITY',
      GET_CITY: 'SAGA_ACTION/STATE_CITY/GET_CITY',
      STATE_LIST: 'SAGA_ACTION/STATE_CITY/STATE_LIST',
      ADD_STATE: 'SAGA_ACTION/STATE_CITY/ADD_STATE',
      UPDATE_STATE: 'SAGA_ACTION/STATE_CITY/UPDATE_STATE',
      DELETE_STATE: 'SAGA_ACTION/STATE_CITY/dELETE_STATE',
      GET_STATE: 'SAGA_ACTION/STATE_CITY/GET_STATE',
   },
   FACILITY: {
      FACILITY_LIST: 'SAGA_ACTION/FACILITY/FACILITY_LIST',
      ADD_FACILITY: 'SAGA_ACTION/FACILITY/ADD_FACILITY',
      UPDATE_FACILITY: 'SAGA_ACTION/FACILITY/UPDATE_FACILITY',
      ADMIN_FACILITY_LIST: 'SAGA_ACTION/FACILITY/ADMIN_FACILITY_LIST',
      DELETE_FACILITY: 'SAGA_ACTION/FACILITY/DELETE_FACILITY',
      GET_FACILITY: 'SAGA_ACTION/FACILITY/GET_FACILITY'
   },
   RESIDENT: {
      ADD_RESIDENT: 'SAGA_ACTION/RESIDENT/ADD_RESIDENT',
      UPDATE_RESIDENT: 'SAGA_ACTION/RESIDENT/UPDATE_RESIDENT',
      RESIDENT_LIST: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST',
      GET_RESIDENT: 'SAGA_ACTION/RESIDENT/GET_RESIDENT',
      ACTIVATE_RESIDENT: 'SAGA_ACTION/RESIDENT/ACTIVATE_RESIDENT',
      INCIDENT: {
         CREATE_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/CREATE_INCIDENT',
         UPDATE_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/UPDATE_INCIDENT',
         LIST_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/LIST_INCIDENT',
         GET_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/GET_INCIDENT',
      },
      RESIDENT_LIST_WITHOUT_COMPANY: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST_WITHOUT_COMPANY',
      THIRD_PARTY_COMPANY_SUBMIT: 'SAGA_ACTION/RESIDENT/THIRD_PARTY_COMPANY_SUBMIT',
   },
   COMMON: {
      UPLOAD_FILE: 'SAGA_ACTION/COMMON/UPLOAD_FILE',
      UPLOAD_FILES: 'SAGA_ACTION/COMMON/UPLOAD_FILES',
      DELETE_FILES: 'SAGA_ACTION/COMMON/DELETE_FILES',
   },
   ANCMENT: {
      UPDATE_ANCMENT: 'SAGA_ACTION/ANCMENT/UPDATE_ANCMENT',
      DELETE_ANCMENT: 'SAGA_ACTION/FACILITY/DELETE_ANCMENT',
      GET_ANCMENT: 'SAGA_ACTION/ANCMENT/GET_ANCMENT',
      CREATE_ANCMENT: 'SAGA_ACTION/ANCMENT/CREATE_ANCMENT',
      LIST_ANCMENTS: 'SAGA_ACTION/ANCMENT/LIST_ANCMENTS',
   },
   MEDICATION: {
      MEDICATION_LIST: 'SAGA_ACTION/MEDICATION/MEDICATION_LIST',
      MEDICATION_LIST_FOR_ADMIN: 'SAGA_ACTION/MEDICATION/MEDICATION_LIST_FOR_ADMIN',
      ADMIN_TEMP_MEDICATION_LIST: 'SAGA_ACTION/MEDICATION/ADMIN_TEMP_MEDICATION_LIST'
   },
   INCIDENT_TYPES: {
      ADD_INCIDENT_TYPE: 'SAGA_ACTION/INCIDENT_TYPES/ADD_INCIDENT_TYPE',
      UPDATE_INCIDENT_TYPE: 'SAGA_ACTION/INCIDENT_TYPES/UPDATE_INCIDENT_TYPE',
      LIST_INCIDENT_TYPES: 'SAGA_ACTION/INCIDENT_TYPES/LIST_INCIDENT_TYPES',
      DELETE_INCIDENT_TYPE: 'SAGA_ACTION/INCIDENT_TYPES/DELETE_INCIDENT_TYPE'
   },
   CONTACT_TYPE: {
      CREATE_CONTACT_TYPE: 'SAGA_ACTION/CONTACT_TYPE/CREATE_CONTACT_TYPE',
      UPDATE_CONTACT_TYPE: 'SAGA_ACTION/CONTACT_TYPE/UPDATE_CONTACT_TYPE',
      LIST_CONTACT_TYPE: 'SAGA_ACTION/CONTACT_TYPE/LIST_CONTACT_TYPE',
      DELETE_CONTACT_TYPE: 'SAGA_ACTION/CONTACT_TYPE/DELETE_CONTACT_TYPE',
      GET_CONTACT_TYPE: 'SAGA_ACTION/CONTACT_TYPE/GET_CONTACT_TYPE'
   },
}

export const PER_PAGE = 10

export const NO_IMAGE = '/assets/images/no-image.jpg'