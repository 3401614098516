import React from "react";
import {Input} from "reactstrap";

export const TextInput = ({ inputref, value, error, ...rest }) => {
   return (
      <>
         {rest.displayType === 'inline' ? null : <label htmlFor={rest.name}>{rest.label} {rest.required && <span className="text-danger">*</span>}</label>}
         <Input
            ref={inputref}
            value={value}
            className="form-control"
            id={rest.name}
            placeholder={rest.placeholder}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
            type={rest.type || 'text'}
            maxLength={rest.maxLength}
            readOnly={rest.readOnly}
         />
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}