import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyTimingsList = React.lazy(() =>
   import('./timingsList')
);

const TimingsList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyTimingsList {...props} />
   </React.Suspense>
);

export default TimingsList