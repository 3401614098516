import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyRelationsList = React.lazy(() =>
   import('./relationsList')
);

const RelationsList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyRelationsList {...props} />
   </React.Suspense>
);

export default RelationsList