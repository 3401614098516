import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyManageContactType = React.lazy(() =>
   import('./manageAdminMedication')
);

const ManageAdminMedications = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyManageContactType {...props} />
   </React.Suspense>
);

export default ManageAdminMedications