import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* stateList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.STATE_CITY.STATE_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* addState(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.STATE_CITY.ADD_STATE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateState(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.STATE_CITY.UPDATE_DELETE_STATE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteState(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.STATE_CITY.UPDATE_DELETE_STATE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getState(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.STATE_CITY.GET_STATE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//cities

export function* cityList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.STATE_CITY.CITY_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* addCity(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.STATE_CITY.ADD_CITY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateCity(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.STATE_CITY.UPDATE_DELETE_CITY + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteCity(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.STATE_CITY.UPDATE_DELETE_CITY + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCity(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.STATE_CITY.GET_CITY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}