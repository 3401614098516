import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addIncidentType(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.INCIDENT_TYPES.ADD_INCIDENT_TYPE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateIncidentType(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.INCIDENT_TYPES.UPDATE_INCIDENT_TYPE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteIncidentType(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.INCIDENT_TYPES.DELETE_INCIDENT_TYPE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listIncidentTypes(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.INCIDENT_TYPES.LIST_INCIDENT_TYPES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}