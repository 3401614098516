import React, { useEffect, useState } from 'react'
import Pagination from "react-js-pagination";
import { PER_PAGE } from '../../config/config';
import { Link, useHistory } from 'react-router-dom'
import { useCompanyApi } from '../../hooks/api/companyApiHook'
import { encryptId, formatPhone } from '../../components/form-elements/functions';
import moment from 'moment'
import { toast } from 'react-toastify';
import { useUserApi } from '../../hooks/api/userApiHook';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import debounce from 'lodash.debounce'
import LazyImage from '../../components/form-elements/lazyImage';

const columns = [{
   dataField: 'id',
   text: '',
   hidden: true
}, {
   dataField: 'photo.fullUrl',
   text: '',
   formatter: (cell, row) => <LazyImage src={row.photo ? row.photo.fullUrl : ''} height="50px" />
}, {
   dataField: 'firstName',
   text: 'First name',
   filter: textFilter(),
   headerStyle: () => {return {minWidth: '120px'}},
   sort: true,
}, {
   dataField: 'lastName',
   text: 'Last name',
   filter: textFilter(),
   headerStyle: () => {return {minWidth: '120px'}},
   sort: true,
}, {
   dataField: 'company.name',
   text: 'Company',
   sort: true,
   filter: textFilter(),
   formatter: (cell, row) => cell ? cell : 'N/A',
   headerStyle: () => {return {minWidth: '120px'}},
},{
   dataField: 'phone',
   text: 'Phone',
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '150px'}},
   formatter: (cell, row) => formatPhone(row.phone)
}, {
   dataField: 'email',
   text: 'Email',
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '120px'}},
}, {
   dataField: 'birthDate',
   text: 'Birth Date',
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '120px'}},
   formatter: (cell, row) => row.birthDate ? moment(row.birthDate).format('MM-DD-YYYY') : 'N/A'
}, {
   dataField: 'isActive',
   text: 'Active',
   sort: true,
   headerStyle: () => {return {minWidth: '120px'}},
   formatter: (cell, row) => cell ? 'Yes' : 'No'
}]


const CompanyUserList = () => {
   const [data, setData] = useState({})
   const [users, setUsers] = useState([])
   const [activePage, setPage] = useState(1)
   const [alert, setAlert] = useState(null)
   const [variables, setVariables] = useState({})
   const [cols, setCols] = useState([])

   const companyApi = useCompanyApi()
   const userApi = useUserApi()
   const history = useHistory()

   useEffect(() => {
      getList(1, {})
      columnSet()

      document.getElementById('c-main').classList.add('overflow-hidden')

      return () => {
         document.getElementById('c-main').classList.remove('overflow-hidden')
      }
   }, [])

   const getList = (page = 1, additional = {}) => {
      let params = { page, userType: 'user', limit: PER_PAGE, ...additional }
      companyApi.listCompanyUsers(params,
         (message, resp) => {
            setUsers(resp.data)
            setData({ total: resp.total, totalPage: resp.totalPage })
         }, (message) => {

         })
   }

   const handlePage = (page) => {
      if (activePage !== page) {
         getList(page, variables)
         setPage(page)
      }
   }

   useEffect(() => {
      columnSet()
   }, [activePage, variables])

   const changeSwitch = (checked, id) => {
      userApi.changeUserState({ id },
         (message, resp) => {
            toast.success(`Owner ${checked ? 'deactivated' : 'activated'} successfully`)
            getList(activePage, variables)
         },
         (message) => {
            toast.error(message)
         })
   }

   const deleteOwner = (id) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Delete Owner?"
            onConfirm={() => confirmDelete(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure?
      </SweetAlert>
      )
   }

   const confirmDelete = (id) => {
      setAlert(null)
      userApi.deleteUser({ id },
         (message, resp) => {
            toast.success('Company owner deleted successfully')
            getList(activePage, variables)
         }, (message) => {
            toast.error(message)
         })
   }

   const columnSet = () => {
      setCols([...columns,
      {
         dataField: 'actions',
         isDummyField: true,
         text: 'Action',
         formatter: (cell, row) => (
            <div className="">
               <i className="fa fa-edit mr-3 cursor-pointer" onClick={() => history.push(`/manage-company-user/${encryptId(row.id)}`)} data-toggle="tooltip" title="Edit Owner" />
               <i className="fa fa-trash cursor-pointer" onClick={() => deleteOwner(row.id)} data-toggle="tooltip" title="Delete Owner" />
            </div>
         )
      }
      ])
   }

   const handleTableChange = debounce((type, { filters, sortField, sortOrder, }) => {
      let searchObj = {}
      for (const dataField in filters) {
         searchObj[dataField.replace('company.name', 'company')] = filters[dataField].filterVal
      }
      if (sortField && sortOrder) {
         searchObj['sort_type'] = sortOrder.toUpperCase()
         searchObj['sort'] = sortField.replace('company.name', 'company')
      }
      setPage(1)
      setVariables(searchObj)
      setUsers([])
      setTimeout(() => {
         getList(1, searchObj)
      }, 1500);
   }, 1500)

   return (
      <div className="container-fluid">
         {alert}
         <div className="fade-in">
            <div className="header-button">
               <h5>Company Owners List</h5>
               <Link className="btn btn-info" to="/manage-company-user">Add Owner</Link>
            </div>
            <div className="row manage_res">
               <div className="col-12">
                  <div className="card">
                     <div className="card-body">
                        <div className="table-responsive data-table">
                           {cols.length ?
                              <BootstrapTable
                                 keyField='id'
                                 remote={{ filter: true, sort: true }}
                                 filter={filterFactory()}
                                 onTableChange={handleTableChange}
                                 data={users}
                                 columns={cols}
                                 noDataIndication={() => data && data.total === 0 ? <p>No results found</p> : <p>Loading...</p>}
                              /> : null}
                        </div>
                        <div className="row mt-2">
                           <div className="col-lg-6">
                              {data.total > 0 && <p>Total results: {data.total}</p>}
                           </div>
                           <div className="col-lg-6 d-flex justify-content-end">
                              {data.totalPage > 1 ?
                                 <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={PER_PAGE}
                                    totalItemsCount={data.total ? data.total : 1}
                                    pageRangeDisplayed={5}
                                    onChange={handlePage}
                                    itemClass="page-item"
                                    hideFirstLastPages={true}
                                    linkClass="page-link"
                                 /> : null}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default CompanyUserList