import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyManageRelation = React.lazy(() =>
   import('./manageRelation')
);

const ManageRelation = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyManageRelation {...props} />
   </React.Suspense>
);

export default ManageRelation