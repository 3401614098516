import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useCompanyApi() {
   const callApi = useApiCall();

   const createCompanyUser = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.CREATE_USER, data, onSuccess, onError);
   }

   const createCompany = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.CREATE_COMPANY, data, onSuccess, onError);
   }

   const updateCompany = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.UPDATE_COMPANY, data, onSuccess, onError);
   }

   const listCompany = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.LIST_COMPANY, data, onSuccess, onError);
   }

   const listCompanyUsers = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.LIST_USERS, data, onSuccess, onError);
   }

   const unAssignedUsers = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.COMPANY_USER, data, onSuccess, onError);
   }

   const companyDetails = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.COMPANY_DETAILS, data, onSuccess, onError);
   }

   const unAssignUser = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.UNASSIGN_USER, data, onSuccess, onError);
   }

   const activateCompany = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.COMPANY_ACTIVATE, data, onSuccess, onError);
   }

   const deleteCompany = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.DELETE_COMPANY, data, onSuccess, onError);
   }

   const listCompanyForAdmin = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMPANY.LIST_COMPANY_FOR_ADMIN, data, onSuccess, onError);
   }

   return {
      listCompany,
      listCompanyUsers,
      createCompany,
      createCompanyUser,
      unAssignedUsers,
      unAssignUser,
      companyDetails,
      deleteCompany,
      updateCompany,
      activateCompany,
      listCompanyForAdmin
   }
}