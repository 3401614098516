import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import loaderReducer from './loader.reducer'
import extrasReducer from './extras.reducer';

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    extras: extrasReducer
});

export default rootReducer;