import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ACTIONS } from "../config/config";
import { useUserApi } from "../hooks/api/userApiHook";
import { useSidebarSelector } from "../hooks/selectors/extrasReducer";
import SweetAlert from "react-bootstrap-sweetalert";

const AuthLayout = (props) => {
  const [alert, setAlert] = useState(null);

  const userApi = useUserApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, isOpen } = useSidebarSelector();

  useEffect(() => {
    const container = document.querySelector("#sidebar-wrap");
    if (container) {
      //const ps = new window.PerfectScrollbar(container);
      //ps.update();
    }
  }, []);

  const toggle = (listId = "") => {
    if (id === listId) {
      dispatch({
        type: ACTIONS.EXTRAS.SIDEBAR,
        payload: { id: listId, isOpen: !isOpen },
      });
    } else {
      dispatch({
        type: ACTIONS.EXTRAS.SIDEBAR,
        payload: { id: listId, isOpen: true },
      });
    }
  };

  const showAlert = (e) => {
    e.preventDefault();
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Continue!"
        confirmBtnBsStyle="danger"
        title="Logout"
        onConfirm={() => logout()}
        onCancel={() => setAlert(null)}
        focusCancelBtn={false}
      >
        Are you sure?
      </SweetAlert>
    );
  };

  const logout = () => {
    setAlert(null);
    userApi.logout(
      (message, resp) => {
        toast.success(message);
        history.replace("/login");
      },
      (message) => {
        toast.error(message);
      }
    );
  };

  const closeMenu = () => {
    document.getElementById("sidebar").classList.remove("c-sidebar-show");
  };

  return (
    <>
      {alert}
      <div>
        <div
          className="c-sidebar c-sidebar-dark c-sidebar-fixed c-sidebar-lg-show"
          id="sidebar"
        >
          <div className="c-sidebar-brand d-lg-down-none">
            {/* 	<svg className="c-sidebar-brand-full" width={118} height={46} alt="CoreUI Logo">
							<use xlinkHref="/assets/images/coreui.svg#full" />
						</svg>
						<svg className="c-sidebar-brand-minimized" width={46} height={46} alt="CoreUI Logo">
							<use xlinkHref="/assets/images/coreui.svg#signet" />
						</svg> */}
            <img
              src="/assets/images/logo-light1.png"
              className="c-sidebar-brand-full cursor-pointer"
              height={50}
              alt="senior wellness Logo"
              onClick={() => history.push("/dashboard")}
            />
            <img
              src="/assets/images/logo-light1.png"
              className="c-sidebar-brand-minimized cursor-pointer"
              height={50}
              alt="senior wellness Logo"
              onClick={() => history.push("/dashboard")}
            />
          </div>
          <i
            className="fa fa-times text-right my-3 mr-3 d-lg-none"
            style={{ color: "#fff", fontSize: "20px" }}
            onClick={closeMenu}
          />
          <ul className="c-sidebar-nav">
            {/* <li className="c-sidebar-nav-title">Menu</li> */}
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/dashboard">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-grid" />
                </svg>{" "}
                Dashboard
              </Link>
            </li>
            {/* <li
              className={`c-sidebar-nav-item c-sidebar-nav-dropdown ${
                id === "company" && isOpen ? "c-show" : ""
              }`}
              onClick={() => toggle("company")}
            >
              <a
                className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-building" />
                </svg>{" "}
                Company
              </a>
              <ul className="c-sidebar-nav-dropdown-items">
                <li className="c-sidebar-nav-item">
                  <NavLink
                    className="c-sidebar-nav-link"
                    to="/company-list"
                    onClick={(e) => e.stopPropagation()}
                    activeClassName="c-active"
                  >
                    <span className="c-sidebar-nav-icon" /> Companies
                  </NavLink>
                </li>
                <li className="c-sidebar-nav-item">
                  <NavLink
                    className="c-sidebar-nav-link"
                    onClick={(e) => e.stopPropagation()}
                    to="/company-owner-list"
                    activeClassName="c-active"
                  >
                    <span className="c-sidebar-nav-icon" /> Company Owners
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/company-list">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-building" />
                </svg>{" "}
                Companies
              </Link>
            </li>
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/relation-list">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-group" />
                </svg>{" "}
                Relations
              </Link>
            </li>
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/timings">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-clock" />
                </svg>{" "}
                Timings
              </Link>
            </li>
            {/* <li className="c-sidebar-nav-item"><Link className="c-sidebar-nav-link" to="/announcements">
                     <svg className="c-sidebar-nav-icon">
                        <use xlinkHref="/assets/images/icons/svg/free.svg#cil-bullhorn" />
                     </svg> Announcements</Link>
                  </li> */}
            {/* <li className="c-sidebar-nav-item">
                     <Link className="c-sidebar-nav-link" to="/staff-list">
                        <svg className="c-sidebar-nav-icon">
                           <use xlinkHref="/assets/images/icons/svg/free.svg#cil-smile" />
                        </svg> Staff</Link>
                  </li> */}
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/facility-list">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-house" />
                </svg>{" "}
                Facilities
              </Link>
            </li>
            {/* <li className="c-sidebar-nav-item">
                     <Link className="c-sidebar-nav-link" to="/resident-list">
                        <svg className="c-sidebar-nav-icon">
                           <use xlinkHref="/assets/images/icons/svg/free.svg#cil-user-plus" />
                        </svg> Residents</Link>
                  </li> */}
                  <li className="c-sidebar-nav-item">
                     <Link className="c-sidebar-nav-link" to="/resident-list-without-company">
                        <svg className="c-sidebar-nav-icon">
                           <use xlinkHref="/assets/images/icons/svg/free.svg#cil-user-plus" />
                        </svg>Residents</Link>
                  </li>
            <li className="c-sidebar-nav-item">
              <Link className="c-sidebar-nav-link" to="/contact-type-list">
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-contact" />
                </svg>{" "}
                Contact Types
              </Link>
            </li>
            {/*  <li className="c-sidebar-nav-item">
                     <Link className="c-sidebar-nav-link" to="/incident-types">
                        <svg className="c-sidebar-nav-icon">
                           <use xlinkHref="/assets/images/icons/svg/free.svg#cil-wheelchair" />
                        </svg> Incident Types
                     </Link>
                  </li> */}
            <li
              className={`c-sidebar-nav-item c-sidebar-nav-dropdown ${
                id === "state-city" && isOpen ? "c-show" : ""
              }`}
              onClick={() => toggle("state-city")}
            >
              <a
                className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <svg className="c-sidebar-nav-icon">
                  <use xlinkHref="/assets/images/icons/svg/free.svg#cil-building" />
                </svg>{" "}
                State & City
              </a>
              <ul className="c-sidebar-nav-dropdown-items">
                <li className="c-sidebar-nav-item">
                  <NavLink
                    className="c-sidebar-nav-link"
                    to="/state-list"
                    onClick={(e) => e.stopPropagation()}
                    activeClassName="c-active"
                  >
                    <span className="c-sidebar-nav-icon" /> State
                  </NavLink>
                </li>
                <li className="c-sidebar-nav-item">
                  <NavLink
                    className="c-sidebar-nav-link"
                    onClick={(e) => e.stopPropagation()}
                    to="/city-list"
                    activeClassName="c-active"
                  >
                    <span className="c-sidebar-nav-icon" /> City
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li className="c-sidebar-nav-title">Components</li> */}
          </ul>
          <button
            className="c-sidebar-minimizer c-class-toggler"
            type="button"
            data-target="_parent"
            data-class="c-sidebar-minimized"
          />
        </div>
        <div className="c-wrapper c-fixed-components" id="sidebar-wrap">
          <header className="c-header c-header-light c-header-fixed c-header-with-subheader">
            <button
              className="c-header-toggler c-class-toggler d-lg-none mfe-auto"
              type="button"
              data-target="#sidebar"
              data-class="c-sidebar-show"
            >
              <svg className="c-icon c-icon-lg">
                <use xlinkHref="/assets/images/icons/svg/free.svg#cil-menu" />
              </svg>
            </button>
            <a className="c-header-brand d-lg-none" href="#">
              <svg width={118} height={46} alt="CoreUI Logo">
                <use xlinkHref="assets/brand/coreui.svg#full" />
              </svg>
            </a>
            <button
              className="c-header-toggler c-class-toggler mfs-3 d-md-down-none"
              type="button"
              data-target="#sidebar"
              data-class="c-sidebar-lg-show"
              responsive="true"
            >
              <svg className="c-icon c-icon-lg">
                <use xlinkHref="/assets/images/icons/svg/free.svg#cil-menu" />
              </svg>
            </button>
            <ul className="c-header-nav ml-auto mr-4">
              <li className="c-header-nav-item dropdown">
                <a
                  className="c-header-nav-link"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="c-avatar">
                    <img
                      className="c-avatar-img"
                      src="/assets/images/profile.png"
                      alt="user@email.com"
                    />
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right pt-0">
                  <div className="dropdown-header bg-light py-2">
                    <strong>Account</strong>
                  </div>
                  <Link className="dropdown-item" to="/profile">
                    <svg className="c-icon mr-2">
                      <use xlinkHref="/assets/images/icons/svg/free.svg#cil-user" />
                    </svg>{" "}
                    Profile
                  </Link>
                  <a className="dropdown-item" href="#" onClick={showAlert}>
                    <svg className="c-icon mr-2">
                      <use xlinkHref="/assets/images/icons/svg/free.svg#cil-account-logout" />
                    </svg>{" "}
                    Logout
                  </a>
                </div>
              </li>
            </ul>
            {/* <div className="c-subheader px-3">
                     <ol className="breadcrumb border-0 m-0">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item"><a href="#">Admin</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                     </ol>
                  </div> */}
          </header>
          <div className="c-body">
            <main className="c-main" id="c-main">
              {props.children}
            </main>
            <footer className="c-footer">
              <div className="w-100 text-center">
                <p>&#169; {new Date().getFullYear()} Senior Wellness Solutions</p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
