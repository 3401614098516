import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import clsx from "clsx";
import { NO_IMAGE } from "../../config/config";

const LazyImage = ({
  className,
  containerClassName,
  src,
  alt,
  onClick,
  height,
  width,
  style,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src !== "") {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoaded(true);
      };
      img.onerror = () => {
        setError(true);
      };
    } else {
      setError(true);
      setLoaded(false);
    }
  }, [src]);

  return (
    <div className={clsx(containerClassName)}>
      {!loaded && !error && <i className="fa fa-spinner fa-spin"></i>}
      {!loaded && error && <img src={NO_IMAGE} height={height} />}
      {loaded && !error && (
        <img
          loading="lazy"
          src={src}
          height={height}
          width={width}
          style={style}
          alt={alt}
          className={clsx("source", loaded && "loaded", className)}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default LazyImage;
