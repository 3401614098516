import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyStatesList = React.lazy(() =>
   import('./statesList')
);

const StatesList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyStatesList {...props} />
   </React.Suspense>
);

export default StatesList