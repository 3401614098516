import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useStateCityApi() {
   const callApi = useApiCall();

   //states

   const listStates = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.STATE_LIST, data, onSuccess, onError);
   }

   const addState = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.ADD_STATE, data, onSuccess, onError);
   }

   const deleteState = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.DELETE_STATE, data, onSuccess, onError);
   }

   const updateState = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.UPDATE_STATE, data, onSuccess, onError);
   }

   const getState = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.GET_STATE, data, onSuccess, onError);
   }

   //cities

   const listCities = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.CITY_LIST, data, onSuccess, onError);
   }

   const addCity = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.ADD_CITY, data, onSuccess, onError);
   }

   const deleteCity = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.DELETE_CITY, data, onSuccess, onError);
   }

   const updateCity = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.UPDATE_CITY, data, onSuccess, onError);
   }

   const getCity = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.STATE_CITY.GET_CITY, data, onSuccess, onError);
   }

   return {
      listStates,
      addState,
      updateState,
      getState,
      deleteState,
      listCities,
      addCity,
      updateCity,
      getCity,
      deleteCity
   }
}