import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyManageCity = React.lazy(() =>
   import('./manageCity')
);

const ManageCity = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyManageCity {...props} />
   </React.Suspense>
);

export default ManageCity