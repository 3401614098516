import React from 'react'
import Select from 'react-select'

export const SelectInput = ({ inputref, value, disabled, error, options, placeholder, onChange, ...props }) => {
   return (
      <>
         <Select
            ref={inputref}
            options={options}
            value={value}
            placeholder={placeholder}
            onChange={val => onChange(val)}
            onBlur={val => props.onBlur(val)}
            isDisabled={disabled}
         />
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}