import React from "react";
import CompanyForm from "../../components/company/companyForm";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { decryptId } from "../../components/form-elements/functions";

const Company = () => {
  const history = useHistory();

  let id = decryptId(useParams().id);

  if (useParams().id && isNaN(id)) {
    toast.error("Invalid link");
    history.replace("/company-list");
  }

  return (
    <div className="container-fluid">
        <CompanyForm companyId={id} heading={id ? "Edit Company" : "Add Company"} />
    </div>
  );
};

export default Company;
