import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyIncidentTypeList = React.lazy(() =>
   import('./incidentTypeList')
);

const IncidentTypeList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyIncidentTypeList {...props} />
   </React.Suspense>
);

export default IncidentTypeList