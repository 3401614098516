import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAdminApi } from '../../hooks/api/adminApiHook'

const Dashboard = () => {
   const [counts, setCounts] = useState({})

   const adminApi = useAdminApi()

   useEffect(() => {
      setData()
   }, [])

   const setData = () => {
      adminApi.dashboard(
         (message, resp) => {
            setCounts(resp)
         }, (message) => {
            //toast.error(message)
         })
   }

   return (
      <div className="container-fluid">
         <div className="fade-in">
            <div className="row">
               <div className="col-sm-6 col-lg-3">
                  <div className="card text-white bg-primary">
                     <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                        <div>
                           <div className="text-value-lg">{counts.residentCount || '0'}</div>
                           <div>Residents</div>
                        </div>
                     </div>
                     <div className="c-chart-wrapper mt-3 mx-3" style={{ height: '70px' }}>
                        <canvas className="chart" id="card-chart1" height={70} />
                     </div>
                  </div>
               </div>
               {/* /.col*/}
               <div className="col-sm-6 col-lg-3">
                  <div className="card text-white bg-info">
                     <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                        <div>
                           <div className="text-value-lg">{counts.facilityCount || '0'}</div>
                           <div>Facilities</div>
                        </div>
                     </div>
                     <div className="c-chart-wrapper mt-3 mx-3" style={{ height: '70px' }}>
                        <canvas className="chart" id="card-chart2" height={70} />
                     </div>
                  </div>
               </div>
               {/* /.col*/}
               <div className="col-sm-6 col-lg-3">
                  <div className="card text-white bg-warning">
                     <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                        <div>
                           <div className="text-value-lg">{counts.staffCount || '0'}</div>
                           <div>Staff</div>
                        </div>
                     </div>
                     <div className="c-chart-wrapper mt-3" style={{ height: '70px' }}>
                        <canvas className="chart" id="card-chart3" height={70} />
                     </div>
                  </div>
               </div>
               {/* /.col*/}
               <div className="col-sm-6 col-lg-3">
                  <div className="card text-white bg-danger">
                     <div className="card-body card-body pb-0 d-flex justify-content-between align-items-start">
                        <div>
                           <div className="text-value-lg">{counts.companyCount || '0'}</div>
                           <div>Companies</div>
                        </div>
                     </div>
                     <div className="c-chart-wrapper mt-3 mx-3" style={{ height: '70px' }}>
                        <canvas className="chart" id="card-chart4" height={70} />
                     </div>
                  </div>
               </div>
               {/* /.col*/}
            </div>
         </div>
      </div>
   )
}

export default Dashboard