import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyResidentList = React.lazy(() =>
   import('./residentList')
);

const ResidentList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyResidentList {...props} />
   </React.Suspense>
);

export default ResidentList