import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* loginUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.LOGIN, data);
      if (resp.status >= 200 && resp.status < 300) {
         localStorage.setItem(STORAGE, resp.data.token);
         yield put({
            type: ACTIONS.USER.LOGIN,
            payload: resp.data,
         });
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getMe(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.ME, data, true);
      yield put({
         type: ACTIONS.USER.ME,
         payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* logout(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.LOGOUT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         localStorage.removeItem(STORAGE);
         yield put({
            type: ACTIONS.USER.LOGOUT,
         });
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else{
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* registerUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.REGISTER, data, false);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {

         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* forgotPassword(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.FORGOT_PASSWORD, data);
      if (resp.status >= 200 && resp.status < 300) {

         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {

         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* resetPassword(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.RESET_PASSWORD, data);
      if (resp.status >= 200 && resp.status < 300) {

         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {

         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* checkToken(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.CHECK_TOKEN, data);
      action && action.callbackSuccess && action.callbackSuccess(resp);
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateProfile(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.UPDATE_PROFILE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.USER.UPDATE_DELETE_USER + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.USER.UPDATE_DELETE_USER + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getUser(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.GET_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* changeUserState(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.CHANGE_USER_STATE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* changePassword(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.CHANGE_PASSWORD, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      }
      else {
         action && action.callbackSuccess && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}