import { useSelector } from "react-redux";

export function useUserSelector() {
    const user = useSelector(state => (state.user ? state.user.user : null))
    return user
}

export function useUserAuthSelector() {
   const isAuth = useSelector(state => (state.user ? state.user.isAuthenticated : null))
   return isAuth
}

export function useUserTypeSelector() {
   const type = useSelector(state => (state.user && state.user.user ? state.user.user.user_type : null))
   return type
}