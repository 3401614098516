import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyCityList = React.lazy(() =>
   import('./cityList')
);

const CityList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyCityList {...props} />
   </React.Suspense>
);

export default CityList