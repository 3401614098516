import React, { useState } from "react";
import { Input } from 'reactstrap';
import Cleave from 'cleave.js/react';

const ExampleCustomTimeInput = React.forwardRef(({ ...rest }, ref) => (
   <input type="text" className="form-control" {...rest} ref={ref} />
));

export const DateInput = ({ inputref, value, error, ...rest }) => {


   const addYear = (value) => {
      value = value.replace(/_/g, '')
      if (value.length == 8) {
         let index = value.lastIndexOf('-') + 1
         let origString = value.split('');
         origString.splice(index, 0, '20');
         let newString = origString.join('');
         rest.onChange(newString)
      }
   }

   return (
      <>
         <label htmlFor={rest.name} className="form-label">{rest.label} {rest.required && <span className="text-danger">*</span>}</label>

         <Cleave
            placeholder="mm-dd-yyyy"
            options={{
               date: true,
               delimiter: '-',
               datePattern: ['m', 'd', 'Y']
            }}
            className="form-control"
            ref={inputref}
            value={value}
            onBlur={e => addYear(e.target.value)}
            onChange={rest.onChange} />

         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}