import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { TextInput } from '../form-elements/textInput'
import { DateInput } from '../form-elements/datepicker';
import { MaskInput } from '../form-elements/maskInput';
import { useCompanyApi } from '../../hooks/api/companyApiHook';
import { toast } from 'react-toastify'
import moment from 'moment'
import { useUserApi } from '../../hooks/api/userApiHook';
import ImageModal from '../form-elements/imageModal';
import { useCommonApi } from '../../hooks/api/commonApiHook';
import { RadioGroup } from '../form-elements/radioGroup';

const schema = yup.object().shape({
   firstName: yup.string().required('First name is required').matches(/^[a-zA-Z]+$/, 'Invalid first name').max(20, 'Max 20 characters'),
   lastName: yup.string().required('Last name is required').matches(/^[a-zA-Z ]+$/, 'Invalid last name').max(40, 'Max 40 characters'),
   username: yup.string().required('Username is required'),
   phone: yup.string().required('Phone is required').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val.length === 10),
   ssn: yup.string().required('SSN is required').max(20, 'Max 20 characters').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid SSN', val => val.length === 9),
   email: yup.string().email('Invalid email').required('Email is required'),
   birthday: yup.string()
      .nullable()
      .test('Length', 'Invalid date of birth', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() && moment(value, 'MM-DD-YYYY').isSameOrBefore();
      }),
});

const CompanyUserForm = (props) => {
   const [name, setName] = useState('')
   const [isOpen, setOpen] = useState(false)
   const [photo, setPhoto] = useState({})

   const companyApi = useCompanyApi()
   const commonApi = useCommonApi()
   const userApi = useUserApi()
   const history = useHistory()

   const { handleSubmit, errors, control, reset, setValue, watch } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   useEffect(() => {
      if (props.userId) {
         getUserDetails()
      }
   }, [])

   const emailVal = watch('email')

   useEffect(() => {
      if ( emailVal && emailVal.length ) {
         setValue('username', emailVal)
      }
   }, [emailVal])

   const getUserDetails = () => {
      userApi.getUser({ id: props.userId },
         (message, resp) => {
            if (resp.birthDate) {
               resp.birthday = moment(resp.birthDate, 'YYYY-MM-DD').format('MM-DD-YYYY')
            }
            setPhoto(resp.photo ? resp.photo : {})
            resp.isActive = resp.isActive == true ? '1' : false
            reset(resp)
            setName(resp.company ? resp.company.name : '')
         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such user found')
               setTimeout(() => {
                  history.replace('/company-owner-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const onSubmit = data => {
      data['userType'] = 2
      if (photo.id) {
         data['photoId'] = photo.id
      }

      data['isActive'] = data.isActive == '1' ? true : false
      if(data?.thirdPartyApiFacility){
         data['thirdPartyApiFacility']=data?.thirdPartyApiFacility
      }
      else{
         data['thirdPartyApiFacility']="0" 
      }

      if (props.userId) {
         data['id'] = props.userId
         data.birthDate = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         delete data.email
         userApi.updateUser(data,
            (message, resp) => {
               toast.success('Owner updated successfully')
               history.push('/company-owner-list')
            }, (message) => {
               toast.error(message)
            })
      }
      else {
         data.birthday = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         companyApi.createCompanyUser(data,
            (message, resp) => {
               toast.success('Owner created successfully')
               history.push('/company-owner-list')
            }, (message) => {
               toast.error(message)
            })
      }
   }

   const uploadImg = () => {
      let formData = new FormData()
      formData.append('file', photo.file)
      formData.append('fileType', 'profile')

      commonApi.uploadFile({ data: formData, format: 'image' },
         (message, resp) => {
            setPhoto(resp)
            setOpen(false)
            toast.success('Please save to confirm changes')
         }, (message) => {
            toast.error(message)
         })
   }

   const handleImg = (e) => {
      let file = e.target.files[0]
      if (file) {
         let url = URL.createObjectURL(file)
         setPhoto({ fullUrl: url, file })
         setOpen(true)
      }
   }

   const closeModal = () => {
      setOpen(false)
      setPhoto({})
      document.getElementById('fileInput').value = ''
   }

   return (
      <>
         <form onSubmit={handleSubmit(onSubmit)}>
            {props.userId && <h6 className="mb-4">Company: <span className="font-weight-bold">{name || 'N/A'}</span></h6>}
            <div className="row">
               <div className="col-sm-6">
                  <div className="form-group">
                     <Controller
                        defaultValue=''
                        control={control}
                        name="firstName"
                        render={({ ref, value, ...props }) => (
                           <TextInput
                              inputref={ref}
                              value={value}
                              error={errors.firstName}
                              label='First Name'
                              required
                              placeholder="First name"
                              type='text'
                              maxLength={20}
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <Controller
                        defaultValue=''
                        control={control}
                        name="lastName"
                        render={({ ref, value, ...props }) => (
                           <TextInput
                              inputref={ref}
                              type='text'
                              value={value}
                              error={errors.lastName}
                              label='Last Name'
                              required
                              placeholder="Last name"
                              maxLength={40}
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-6" data-select2-id={8}>
                  <div className="form-group" data-select2-id={15}>
                     <Controller
                        defaultValue=''
                        control={control}
                        name="email"
                        render={({ ref, value, ...rest }) => (
                           <TextInput
                              inputref={ref}
                              type='text'
                              value={value}
                              error={errors.email}
                              label='Email'
                              required
                              readOnly={props.userId ? true : false}
                              placeholder="Email"
                              {...rest}
                           />
                        )}
                     />
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <label>Date of Birth <span className="text-danger">*</span></label>
                     <Controller
                        defaultValue={''}
                        control={control}
                        name="birthday"
                        render={({ ref, value, ...props }) => (
                           <DateInput
                              inputref={ref}
                              value={value}
                              error={errors.birthday}
                              placeholder='mm-dd-yyyy'
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                     <Controller
                        defaultValue=''
                        control={control}
                        name="phone"
                        render={({ ref, value, ...props }) => (
                           <MaskInput
                              mask="(999) 999-9999"
                              maskPlaceholder="Phone"
                              inputref={ref}
                              value={value}
                              error={errors.phone}
                              label='Phone'
                              required
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="form-group">
                     <Controller
                        defaultValue=''
                        control={control}
                        name="username"
                        render={({ ref, value, ...rest }) => (
                           <TextInput
                              inputref={ref}
                              value={value}
                              error={errors.username}
                              label='Username'
                              required
                              maxLength={20}
                              placeholder="Username"
                              type='text'
                              {...rest}
                           />
                        )}
                     />
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-sm-6" data-select2-id={9}>
                  <div className="form-group" data-select2-id={15}>
                     <Controller
                        defaultValue=''
                        control={control}
                        name="ssn"
                        render={({ ref, value, ...props }) => (
                           <MaskInput
                              mask="999-99-9999"
                              maskPlaceholder="SSN"
                              inputref={ref}
                              value={value}
                              error={errors.ssn}
                              label='SSN'
                              required
                              {...props}
                           />
                        )}
                     />
                  </div>
               </div>
            </div>

            <div className="row mt-2">
               <div className="col-lg-12">
                  <div className="form-group">
                     <Controller
                        defaultValue={'1'}
                        control={control}
                        name="isActive"
                        render={({ ref, value, ...rest }) => (
                           <RadioGroup
                              name="isActive"
                              value={value}
                              inputref={ref}
                              options={[
                                 { label: 'Active', value: '1' },
                                 { label: 'Inactive', value: '0' }
                              ]}
                              {...rest}
                           />
                        )}
                     />
                  </div>
               </div>
            </div>

            <div className="row">
               <div className="col-lg-6">
                  <div className="form-group photo-display">
                     <label className="form-label">Photo</label>
                     {photo && photo.id ?
                        <div>
                           <img src={photo.fullUrl} className="d-block mb-3" style={{ maxHeight: '150px', maxWidth: '100%' }} />
                           <button className="btn btn-danger" onClick={() => setPhoto({})} type="button">Remove Photo</button>
                        </div>
                        :
                        <div className="custom-file">
                           <input type="file" className="custom-file-input" onChange={handleImg} accept=".jpg,.jpeg,.png" id="fileInput" />
                           <label className="custom-file-label" htmlFor="fileInput">Choose file</label>
                        </div>
                     }
                  </div>
               </div>
            </div>

            <div className="float-right mt-3">
               <button type="submit" className="btn btn-info waves-effect">Save</button>
            </div>
         </form>
         <ImageModal
            isOpen={isOpen}
            url={photo && photo.fullUrl ? photo.fullUrl : ''}
            close={closeModal}
            onSave={uploadImg}
         />
      </>
   )
}

export default CompanyUserForm