import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyManageState = React.lazy(() =>
   import('./manageState')
);

const ManageState = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyManageState {...props} />
   </React.Suspense>
);

export default ManageState