import { ACTIONS } from "../../config/config";

const initialState = {
   isAuthenticated: false,
   token: null,
   user: null
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.USER.LOGIN:
         let token = action.payload.token
         let user =  action.payload
         delete user.token

         return {
            ...state,
            isAuthenticated: true,
            token,
            user
         }
      case ACTIONS.USER.LOGOUT:
         return {
            ...state,
            isAuthenticated: false,
            token: null,
            user: null,
         }
      case ACTIONS.USER.ME:
         return {
            ...state,
            user: action.payload,
            isAuthenticated: true
         }
      default:
         return state;
   }
}
export default userReducer;
