import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyResidentWithoutCompanyList = React.lazy(() =>
   import('./residentWithoutCompanyList')
);

const residentWithoutCompanyLists = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyResidentWithoutCompanyList {...props} />
   </React.Suspense>
);

export default residentWithoutCompanyLists