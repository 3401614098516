import Hashids from 'hashids'
import { SITE_URL } from '../../config/config'
const hashids = new Hashids('ThIsAS3cRet*Key#S3Nior', 25)

export const formatPhone = (phone) => {
   let str = phone ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}` : ''
   return str
}

export const formatSSN = (ssn) => {
   let str = ssn ? `${ssn.slice(0,3)}-${ssn.slice(3,5)}-${ssn.slice(5,9)}` : ''
   return str
}

export const makePhotoLink = (str) => {
   str = str.replace(/\\/g, '/')
   return SITE_URL + '/' + str
}

export const encryptId = (id) => {
   return hashids.encode(id)
}

export const decryptId = (str) => {
   return hashids.decode(str)[0]
}


export const timeName = (time) => {
   switch (time) {
      case 'Am':
         return 'Morning'
      case 'Pm':
         return 'Afternoon'
      case 'Bed':
         return 'Bed Time'
      case 'Evening':
         return 'Evening'
      case 'CmfKit':
         return 'Comfort Kit'
      case 'Pnr':
         return 'As Needed'
      case 'Treatment':
         return 'Treatment'
      default:
         return ''
   }
}