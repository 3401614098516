import { ACTIONS } from '../../config/config';

const initialState = {
   sidebar: {},
};

const extrasReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.EXTRAS.SIDEBAR:
         return {
            ...state,
            sidebar: action.payload
         };

      default:
         return state
   }
}

export default extrasReducer