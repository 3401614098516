import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyViewResident = React.lazy(() =>
   import('./viewResident')
);

const ViewResident = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyViewResident {...props} />
   </React.Suspense>
);

export default ViewResident