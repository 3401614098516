import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../config/config';
import { dashboard } from './admin.saga';
import { ancmentDetails, ancmentList, createAncment, deleteAncment, updateAncment } from './ancment.saga';
import { uploadFile } from './common.saga'
import {
   createCompany,
   createCompanyUser,
   companyList,
   companyUserList,
   unAssignedUsers,
   companyDetails,
   updateCompanyDetails,
   unAssignUser,
   companyActivate,
   deleteCompany,
   companyListForAdmin
} from './company.saga';
import { createContactType, deleteContactType, getContactType, listContactTypes, updateContactType } from './contactType.saga';

import {
   addFacility,
   adminFacilityList,
   deleteFacility,
   facilityList,
   getFacility,
   updateFacility
} from './facility.saga';
import { addIncidentType, deleteIncidentType, listIncidentTypes, updateIncidentType } from './incidentType.saga';

import {
   createRelation,
   deleteRelation,
   getRelation,
   listRelation,
   updateRelation
} from './relations.saga';

import {
   addResident,
   listIncidents,
   listMedication,
   listMedicationsAdmin,
   newListMedicationsAdmin,
   residentDetails,
   residentList,
   residentListwithoutCompanys,
   updateResident,
   checkNewResident
} from './resident.saga';

import { listRoles } from './role.saga';

import {
   addCity,
   addState,
   cityList,
   deleteCity,
   deleteState,
   getCity,
   getState,
   stateList,
   updateCity,
   updateState
} from './stateCity.saga';

import {
   createTiming,
   deleteTiming,
   listTiming,
   updateTimings
} from './timings.saga';

import {
   loginUser,
   logout,
   getMe,
   registerUser,
   resetPassword,
   forgotPassword,
   checkToken,
   updateProfile,
   getUser,
   updateUser,
   changeUserState,
   deleteUser,
   changePassword,
} from './user.saga';

export function* sagas() {
   yield all([

      takeLatest(SAGA_ACTIONS.ADMIN.DASHBOARD, dashboard),

      //Auth  
      takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
      takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
      takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
      takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
      takeLatest(SAGA_ACTIONS.USER.ME, getMe),
      takeLatest(SAGA_ACTIONS.USER.REGISTER, registerUser),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
      takeLatest(SAGA_ACTIONS.USER.CHECK_TOKEN, checkToken),
      takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, changePassword),
      takeLatest(SAGA_ACTIONS.USER.CREATE_USER, createCompanyUser),
      takeLatest(SAGA_ACTIONS.USER.LIST_USERS, companyUserList),
      takeLatest(SAGA_ACTIONS.USER.UPDATE_USER, updateUser),
      takeLatest(SAGA_ACTIONS.USER.GET_USER, getUser),
      takeLatest(SAGA_ACTIONS.USER.DELETE_USER, deleteUser),
      takeLatest(SAGA_ACTIONS.USER.CHANGE_USER_STATE, changeUserState),

      //company
      takeLatest(SAGA_ACTIONS.COMPANY.CREATE_COMPANY, createCompany),
      takeLatest(SAGA_ACTIONS.COMPANY.LIST_COMPANY, companyList),
      takeLatest(SAGA_ACTIONS.COMPANY.LIST_COMPANY_FOR_ADMIN, companyListForAdmin),
      takeLatest(SAGA_ACTIONS.COMPANY.COMPANY_USER, unAssignedUsers),
      takeLatest(SAGA_ACTIONS.COMPANY.COMPANY_DETAILS, companyDetails),
      takeLatest(SAGA_ACTIONS.COMPANY.UPDATE_COMPANY, updateCompanyDetails),
      takeLatest(SAGA_ACTIONS.COMPANY.DELETE_COMPANY, deleteCompany),
      takeLatest(SAGA_ACTIONS.COMPANY.UNASSIGN_USER, unAssignUser),
      takeLatest(SAGA_ACTIONS.COMPANY.COMPANY_ACTIVATE, companyActivate),

      //relations
      takeLatest(SAGA_ACTIONS.RELATIONS.LIST_RELATION, listRelation),
      takeLatest(SAGA_ACTIONS.RELATIONS.CREATE_RELATION, createRelation),
      takeLatest(SAGA_ACTIONS.RELATIONS.DELETE_RELATION, deleteRelation),
      takeLatest(SAGA_ACTIONS.RELATIONS.UPDATE_RELATION, updateRelation),
      takeLatest(SAGA_ACTIONS.RELATIONS.GET_RELATION, getRelation),
      
      //timings
      takeLatest(SAGA_ACTIONS.TIMINGS.LIST_TIMING, listTiming),
      takeLatest(SAGA_ACTIONS.TIMINGS.CREATE_TIMING, createTiming),
      takeLatest(SAGA_ACTIONS.TIMINGS.DELETE_TIMING, deleteTiming),
      takeLatest(SAGA_ACTIONS.TIMINGS.UPDATE_TIMING, updateTimings),

      //roles
      takeLatest(SAGA_ACTIONS.ROLES.LIST_ROLES, listRoles),

      //state
      takeLatest(SAGA_ACTIONS.STATE_CITY.ADD_STATE, addState),
      takeLatest(SAGA_ACTIONS.STATE_CITY.UPDATE_STATE, updateState),
      takeLatest(SAGA_ACTIONS.STATE_CITY.STATE_LIST, stateList),
      takeLatest(SAGA_ACTIONS.STATE_CITY.DELETE_STATE, deleteState),
      takeLatest(SAGA_ACTIONS.STATE_CITY.GET_STATE, getState),

      //city
      takeLatest(SAGA_ACTIONS.STATE_CITY.ADD_CITY, addCity),
      takeLatest(SAGA_ACTIONS.STATE_CITY.UPDATE_CITY, updateCity),
      takeLatest(SAGA_ACTIONS.STATE_CITY.CITY_LIST, cityList),
      takeLatest(SAGA_ACTIONS.STATE_CITY.DELETE_CITY, deleteCity),
      takeLatest(SAGA_ACTIONS.STATE_CITY.GET_CITY, getCity),

      //facility
      takeLatest(SAGA_ACTIONS.FACILITY.FACILITY_LIST, facilityList),
      takeLatest(SAGA_ACTIONS.FACILITY.ADD_FACILITY, addFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.DELETE_FACILITY, deleteFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.UPDATE_FACILITY, updateFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.GET_FACILITY, getFacility),
      takeLatest(SAGA_ACTIONS.FACILITY.ADMIN_FACILITY_LIST, adminFacilityList),
      

      //resident
      takeLatest(SAGA_ACTIONS.RESIDENT.ADD_RESIDENT, addResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST, residentList),
      takeLatest(SAGA_ACTIONS.RESIDENT.UPDATE_RESIDENT, updateResident),
      takeLatest(SAGA_ACTIONS.RESIDENT.GET_RESIDENT, residentDetails),

      takeLatest(SAGA_ACTIONS.RESIDENT.INCIDENT.LIST_INCIDENT, listIncidents),
      takeLatest(SAGA_ACTIONS.RESIDENT.RESIDENT_LIST_WITHOUT_COMPANY, residentListwithoutCompanys),
      takeLatest(SAGA_ACTIONS.RESIDENT.THIRD_PARTY_COMPANY_SUBMIT, checkNewResident),

      //announcement
      takeLatest(SAGA_ACTIONS.ANCMENT.CREATE_ANCMENT, createAncment),
      takeLatest(SAGA_ACTIONS.ANCMENT.LIST_ANCMENTS, ancmentList),
      takeLatest(SAGA_ACTIONS.ANCMENT.DELETE_ANCMENT, deleteAncment),
      takeLatest(SAGA_ACTIONS.ANCMENT.GET_ANCMENT, ancmentDetails),
      takeLatest(SAGA_ACTIONS.ANCMENT.UPDATE_ANCMENT, updateAncment),

      takeLatest(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST, listMedication),
      takeLatest(SAGA_ACTIONS.COMMON.UPLOAD_FILE, uploadFile),
      takeLatest(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST_FOR_ADMIN, listMedicationsAdmin),
      takeLatest(SAGA_ACTIONS.MEDICATION.ADMIN_TEMP_MEDICATION_LIST, newListMedicationsAdmin),

      //incident types
      takeLatest(SAGA_ACTIONS.INCIDENT_TYPES.ADD_INCIDENT_TYPE, addIncidentType),
      takeLatest(SAGA_ACTIONS.INCIDENT_TYPES.UPDATE_INCIDENT_TYPE, updateIncidentType),
      takeLatest(SAGA_ACTIONS.INCIDENT_TYPES.DELETE_INCIDENT_TYPE, deleteIncidentType),
      takeLatest(SAGA_ACTIONS.INCIDENT_TYPES.LIST_INCIDENT_TYPES, listIncidentTypes),

      //contact types
      takeLatest(SAGA_ACTIONS.CONTACT_TYPE.CREATE_CONTACT_TYPE, createContactType),
      takeLatest(SAGA_ACTIONS.CONTACT_TYPE.UPDATE_CONTACT_TYPE, updateContactType),
      takeLatest(SAGA_ACTIONS.CONTACT_TYPE.DELETE_CONTACT_TYPE, deleteContactType),
      takeLatest(SAGA_ACTIONS.CONTACT_TYPE.LIST_CONTACT_TYPE, listContactTypes),
      takeLatest(SAGA_ACTIONS.CONTACT_TYPE.GET_CONTACT_TYPE, getContactType),

   ]);
}