import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthLayout from '../hoc/authLayout'
import { useUserAuthSelector, useUserTypeSelector } from '../hooks/selectors/userSelector'

const PrivateRoute = ({ component: Component, ...rest }) => {
   let user_type = useUserTypeSelector()
   let isAuthenticated = useUserAuthSelector()

   return (
      <Route {...rest} render={props => {
         if (!isAuthenticated) {
            return <Redirect to='/login' />
         }

         // check if route is restricted by role
         /* if (rest.userTypes?.indexOf(user_type) === -1) {
             return <Redirect to='/dashboard' />
         } */

         return (
            <AuthLayout>
               <Component {...props} />
            </AuthLayout>
         )
      }}
      />
   )
}

export default PrivateRoute