import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* createRelation(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RELATIONS.CREATE_RELATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listRelation(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RELATIONS.LIST_RELATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateRelation(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RELATIONS.UPDATE_DELETE_RELATION + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteRelation(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.RELATIONS.UPDATE_DELETE_RELATION + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getRelation(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RELATIONS.GET_RELATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}