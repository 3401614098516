import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyProfile = React.lazy(() =>
   import('./profile')
);

const Profile = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyProfile {...props} />
   </React.Suspense>
);

export default Profile