import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import CompanyUserForm from '../../components/company/companyUserForm'
import { decryptId } from '../../components/form-elements/functions'

const CompanyUser = () => {
   const history = useHistory()

   let id = decryptId(useParams().id)
   if (useParams().id && isNaN(id)) {
      toast.error('Invalid link')
      history.replace('/company-owner-list')
   }

   return (
      <div className="container-fluid">
         <div className="row">
            <div className="col-sm-7">
               <div className="card">
                  <div className="card-header"><strong>{id ? 'Edit' : 'Create'} Company Owner</strong></div>
                  <div className="card-body">
                     <CompanyUserForm userId={id} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default CompanyUser