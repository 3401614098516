import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useAdminApi() {
   const callApi = useApiCall();

   const dashboard = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ADMIN.DASHBOARD, null, onSuccess, onError);
   }

   return {
      dashboard
   }
}