import React, { useEffect, useState } from 'react'
import Pagination from "react-js-pagination";
import { PER_PAGE } from '../../config/config';
import { Link, useHistory } from 'react-router-dom'
import { useCompanyApi } from '../../hooks/api/companyApiHook'
import { encryptId, formatPhone } from '../../components/form-elements/functions';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import debounce from 'lodash.debounce'

const columns = [{
   dataField: 'id',
   text: '',
   hidden: true
}, {
   dataField: 'name',
   text: 'Company Name',
   filter: textFilter(),
   headerStyle: () => {return {minWidth: '170px'}},
   sort: true
},
// {
//    dataField: 'regNo',
//    text: 'Reg. No.',
//    headerStyle: () => {return {minWidth: '120px'}},
//    filter: textFilter(),
//    sort: true
// },
{
   dataField: 'phone',
   text: 'Phone',
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '150px'}},
   formatter: (cell, row) => formatPhone(row.phone)
}, {
   dataField: 'state.state_code',
   text: 'State',
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '130px'}},
}, {
   dataField: 'city',
   text: 'City',
   filter: textFilter(),
   headerStyle: () => {return {minWidth: '130px'}},
   sort: true,
}, {
   dataField: 'zipCode',
   text: 'Zip',
   filter: textFilter(),
   headerStyle: () => {return {minWidth: '120px'}},
   sort: true,
}, {
   dataField: 'companyUser.id',
   text: 'cid',
   hidden: true
}, {
   dataField: 'full_name',
   text: 'Assigned',
   isDummyField: true,
   filter: textFilter(),
   sort: true,
   headerStyle: () => {return {minWidth: '120px'}},
   formatter: (cell, row) => row.companyUser ? `${row.companyUser.firstName} ${row.companyUser.lastName}` : 'Unassigned'
}, {
   dataField: 'isActive',
   text: 'Active',
   sort: true,
   headerStyle: () => {return {minWidth: '120px'}},
   formatter: (cell, row) => cell ? 'Yes' : 'No'
}
]

const CompanyList = () => {
   const [data, setData] = useState([])
   const [companies, setCompanies] = useState([])
   const [activePage, setPage] = useState(1)
   const [alert, setAlert] = useState(null)
   const [variables, setVariables] = useState({})
   const [cols, setCols] = useState([])

   const companyApi = useCompanyApi()
   const history = useHistory()

   useEffect(() => {
      getList(1)
      columnSet()

      document.getElementById('c-main').classList.add('overflow-hidden')

      return () => {
         document.getElementById('c-main').classList.remove('overflow-hidden')
      }
   }, [])

   useEffect(() => {
      columnSet()
   }, [activePage, variables])

   const getList = (page, additional = {}) => {
      let params = { page, limit: PER_PAGE, ...additional }
      companyApi.listCompany(params,
         (message, resp) => {
            setCompanies(resp.data)
            setData({ total: resp.total, totalPage: resp.totalPage })
         }, (message) => {

         })
   }

   const changeSwitch = (checked, id) => {
      companyApi.activateCompany({ id },
         (message, resp) => {
            toast.success(`Company ${checked ? 'deactivated' : 'activated'} successfully`)
            getList(activePage, variables)
         },
         (message) => {
            toast.error(message)
         })
   }

   const showConfirm = (cid, uid) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Delete Company?"
            onConfirm={() => deleteCompany(cid, uid)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            {uid !== '' && 'Deleting the company will delete the assigned owner as well. '}
            {'This action cannot be undone. Are you sure?'}
      </SweetAlert>
      )
   }

   const deleteCompany = (cid, uid) => {
      setAlert(null)
      companyApi.deleteCompany({ id: cid, userId: uid },
         (message, resp) => {
            toast.success('Company deleted successfully')
            getList(activePage, variables)
         },
         (message) => {
            toast.error(message)
         })
   }

   const columnSet = () => {
      setCols([...columns,
      {
         dataField: 'action',
         isDummyField: true,
         style: { minWidth: '100px' },
         text: 'Action',
         formatter: (cell, row) => (
            <div className="">
               <i className="fa fa-edit mr-3 cursor-pointer" onClick={() => history.push(`/manage-company/${encryptId(row.id)}`)} data-toggle="tooltip" title="Edit Company" />
               <i className="fa fa-trash cursor-pointer" data-toggle="tooltip" title="Delete Company" onClick={() => showConfirm(row.id, row.companyUser ? row.companyUser.id : '')} />
            </div>
         )
      }
      ])
   }

   const handlePage = (page) => {
      if (activePage !== page) {
         getList(page, variables)
         setPage(page)
      }
   }

   const handleTableChange = debounce((type, { filters, sortField, sortOrder, }) => {
      let searchObj = {}
      for (const dataField in filters) {
         searchObj[dataField.replace('companyUser.', '')] = filters[dataField].filterVal
      }
      if (sortField && sortOrder) {
         searchObj['sort_type'] = sortOrder.toUpperCase()
         searchObj['sort'] = sortField.replace('companyUser.', '')
      }
      setCompanies([])
      setPage(1)
      setVariables(searchObj)
      setTimeout(() => {
         getList(1, searchObj)
      }, 1500);
   }, 1500)

   return (
      <>
         {alert}
         <div className="container-fluid">
            <div className="fade-in">
               <div className="header-button">
                  <h5>Company List</h5>
                  <Link className="btn btn-info" to="/manage-company">Add Company</Link>
               </div>
               <div className="row manage_res">
                  <div className="col-12">
                     <div className="card">
                        <div className="card-body">
                           <div className="table-responsive data-table">
                              {cols.length ?
                                 <BootstrapTable
                                    keyField='id'
                                    remote={{ filter: true, sort: true }}
                                    filter={filterFactory()}
                                    onTableChange={handleTableChange}
                                    data={companies}
                                    columns={cols}
                                    noDataIndication={() => data && data.total === 0 ? <p>No results found</p> : <p>Loading...</p>}
                                 /> : null}
                           </div>
                           <div className="row mt-2">
                              <div className="col-lg-6">
                                 {data.total > 0 && <p>Total results: {data.total}</p>}
                              </div>
                              <div className="col-lg-6 d-flex justify-content-end">
                                 {data.totalPage > 1 ?
                                    <Pagination
                                       activePage={activePage}
                                       itemsCountPerPage={PER_PAGE}
                                       totalItemsCount={data.total ? data.total : 1}
                                       pageRangeDisplayed={5}
                                       hideFirstLastPages={true}
                                       onChange={handlePage}
                                       itemClass="page-item"
                                       linkClass="page-link"
                                    /> : null}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default CompanyList